@font-face {
  font-family: TitilliumWeb-Bold;
  src: url("../font/TitilliumWeb-Bold.ttf") format("truetype");
}
//@font-face {
//  font-family: Roboto;
//  src: url("../font/Roboto-Black.ttf") format("truetype");
//}
@font-face {
  font-family: Roboto;
  src: url("../font/Roboto-Light.ttf") format("truetype");
}@font-face {
  font-family: Roboto-bold;
  src: url("../font/Roboto-Bold.ttf") format("truetype");
}

