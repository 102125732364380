/*******************
 Breadcrumb and page title
*******************/
.spazio{
  margin-top: 0;
  width: 24%;
  font-variant: all-petite-caps;
  z-index: 1;
  background-color: #ffffff;
  position: fixed;
  left: 0;
  height: 40px;
}
.page-breadcrumb {
    padding: 0 20px 0 20px;
  height: 40px;
  border-top: 1pt;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-color: #53c954;
  margin-top: 0;
  border-style: solid;
  width: 85%;
  font-variant: all-petite-caps;
  position: fixed;
  right: 0;
  z-index: 1;
  background-color: #ddddde;
    .page-title{
        //color: $info;
      text-align: center;
      color: #0c0c0c;
    }
    .breadcrumb {

        margin: 0px;
        height: min-content;
        background: transparent;
      text-align: center;
        font-size: 12pt;
        .breadcrumb-item+.breadcrumb-item::before {
            content: "\e649";
            font-family: themify;
          text-align: center;
            color: #53c154;
            font-size: 11px;
        }
      a{
        color: #53c154;
      }
        .breadcrumb-item.active {
          text-align: center;
            //color: $muted;
          color: #53c154;
        }
    }
}
